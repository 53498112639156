import { createBrowserRouter } from "react-router-dom"
import UsersList from "../features/users-list/users-list-view"
import SignInView from "../features/signin/signin"
import LogoutView from "../features/logout/logout"
import HandleRaffleView from "../features/raffles/handle-raffle-view"
import RafflesView from "../features/raffles-list/raffles-list-view"
import OfficesListView from "../features/offices/offices-list/offices-list-view"
import HandleOfficeView from "../features/offices/handle-office/handle-office-view"
import CustomerListView from "../features/customers/customers-list/customers-list-view"
import CustomerView from "../features/customers/customer-view/customer-view"
import ClientTypeUpdateView from "../features/customer-situations/client-situations/client-situations-view"
import CurrentCampaignView from "../features/campaigns/current-campaign/current-campaign-view"
import CampaignLeadView from "../features/campaigns/campaign-lead/campaign-lead-view"
import ImportNumbersView from "../features/customers/import-numbers/import-numbers-view"
import DashboardView from "../features/dashboard/dashboard-view"
import UntrustedPaymentsView from "../features/payments/untrusted-payments/untrusted-payments-view"
import { HandleUserView } from "../features/handle-user/handle-user-view"
import ReportsView from "../views/reports-view"
import ExternalsView from "../features/externals/external-view"
import EventGatewayView from "../views/event-gateway.view"
import CustomerCenterView from "../views/customer-center-view"
import LeadsAuditoryView from "../views/leads-auditory-view"
import OfficeDashboardView from "../views/office-dashboard-view"
import StepsView from "../views/steps-view"
import CustomerDatabaseView from "../views/customer-database-view"
import FaceAuthView from "../views/face-auth-view"
import FaceAuthFaceDetection from "../views/face-auth-face-detection"
import CustomerStepsLogView from "../views/customer-steps-log-view"
import AuditResumeView from "../views/auditory-resume-view"
import UserDocumentsView from "../views/user-documents-view"
import CustomersActivesSnapShotsView from "../views/customers-actives-snap-shot-view"
import UserPercentageView from "../views/user-percentage-view"
import PaymentRouteTemplateView from "../views/pay-route-template-view"
import UsersWithoutCustomersView from "../views/users-without-customers.view"
import DownloadPaymentView from "../views/download-payment-view"
import DownloadPayHistoryView from "../views/download-pays-history-view"
import HandleExpensesView from "../features/handle-expenses/handle-expenses"
import CalculatorMultiplePaysView from "../views/calculator-view"
import CollectorsView from "../views/collectors-view"
import DashboardContent from "../views/dashboard-view"
import QrArriveView from "../views/qr-arrive-view"
import SettingsView from "../views/settings-view"
import TwilioNumbersView from "../views/twilio-numbers-view"
import OfficeLevelView from "../views/office-level-view"
import VerifyPaymentsView from "../views/verify-payments-view"


const router = createBrowserRouter([
  { path: "/", element: <SignInView /> },
  { path: "", element: <SignInView /> },
  { path: "/login", element: <SignInView /> },
  { path: "/logout", element: <LogoutView /> },
  {
    path: "/dashboard",
    element: <DashboardView />,
    children: [

    //Campaigns
    { path: "", element: <DashboardContent /> },
    { path: "campaign", element: <CurrentCampaignView /> },
    { path: "lead-campaign", element: <CampaignLeadView /> },

    //Reports
    { path: "reports", element: <ReportsView /> },
    { path: "unstrusted-payments", element: <UntrustedPaymentsView /> },

    //Type Setup
    { path: "situations", element: <ClientTypeUpdateView /> },

    //Numbers
    { path: "numbers", element: <CustomerListView /> },
    { path: "customer/:customerId", element: <CustomerView /> },
    { path: "imports-numbers", element: <ImportNumbersView /> },
    { path: "externals", element: <ExternalsView /> },
    { path: "customers-center", element: <CustomerCenterView /> },
    { path: "leads-auditory", element: <LeadsAuditoryView /> },
    { path: "office-dashboard/:officeId", element: <OfficeDashboardView /> },
    { path: "customers-database", element: <CustomerDatabaseView /> },
    { path: "steps", element: <StepsView /> },
    { path: "step-logs", element: <CustomerStepsLogView /> },
    { path: "audit-resume", element: <AuditResumeView /> },
    { path: "documents", element: <UserDocumentsView /> },
    { path: "user-actives-snap-shot", element: <CustomersActivesSnapShotsView /> },
    { path: "users-percentage", element: <UserPercentageView /> },
    { path: "payment-route-template", element: <PaymentRouteTemplateView/> },
    { path: "download-payment", element: <DownloadPaymentView/> },
    { path: "download-pays-history", element: <DownloadPayHistoryView/> },
    { path: "users-with-not-customer", element: <UsersWithoutCustomersView/> },
    { path: "twilio-numbers", element: <TwilioNumbersView/> },
    
    //Offices
    { path: "offices-list", element: <OfficesListView /> },
    { path: "handle-office", element: <HandleOfficeView /> },
    { path: "handle-office/:officeId", element: <HandleOfficeView /> },
    { path: "office-levels", element: <OfficeLevelView /> },
    
    //Socket
    { path: "events-gateway", element: <EventGatewayView /> },
    { path: "face-auth", element: <FaceAuthView /> },
    { path: "face-auth-cam-face", element: <FaceAuthFaceDetection /> },
    { path: "log-arrive", element: <QrArriveView /> },
    { path: "settings", element: <SettingsView /> },

    
    //Raffles
    { path: "create-raffle", element: <HandleRaffleView /> },
    { path: "handel-raffle/:raffleId", element: <HandleRaffleView /> },
    { path: "raffles", element: <RafflesView /> },
    
    
    //User
    { path: "handle-user", element: <HandleUserView /> },
    { path: "handle-user/:userId", element: <HandleUserView /> },
    { path: "users", element: <UsersList /> },
    { path: "collectors", element: <CollectorsView /> },

    { path: "expenses", element: <HandleExpensesView /> },
    { path: "calculator", element: <CalculatorMultiplePaysView /> },
    { path: "verify-payments", element: <VerifyPaymentsView /> },

      // { path: "create-card", element: <HandleCard /> },
      // { path: "cards-lists", element: <CardsList /> },
      // { path: "cards-lists/:userId", element: <CardsList /> },
      // { path: "cards-history", element: <HistoryCards /> },
      // { path: "closure", element: <ClosureView /> },
      // { path: "closure/:userId", element: <ClosureView /> },
      // { path: "capital-contribute", element: <CapitalContribute /> },
      // { path: "cards-lists/:userId", element: <CardsList /> },
      // { path: "handle-card/:cardId", element: <HandleCard /> },
    ],
  },
])

export default router
