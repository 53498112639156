import { Autocomplete, TextField } from "@mui/material";

export type AppAutocompleteOption = {
  _id: string,
  name: string
}

export default function AppAutoComplete(
  {multiple = false, options, name, onChange, value = [], label = "", placeholder = ""} :
  {multiple? : boolean, options: AppAutocompleteOption[], name: string, 
    value?:  AppAutocompleteOption[] | AppAutocompleteOption, label?: string, placeholder?: string,
    onChange: ({name, val} : {name: string, val: any}) => void
  }
) {
  //const dispatch = useAppDispatch()
  //const {} = useAppSelector((state) => state.users) 
  return (
    <>
      <Autocomplete
        size="small"
        multiple={multiple}
        filterSelectedOptions
        getOptionLabel={(option) => option.name === undefined ?  "" : option.name}
        onChange={(e, value) => onChange({name, val: value})}
        options={options}
        value={value}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            placeholder={placeholder}
          />
        )}
      />

    </>
  )
}