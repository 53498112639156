import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import { useAppSelector } from "../../../../app/hooks"
import { RootState } from "../../../../app/store"
import { dateUTCToFriendly } from "../../../../utils/date.utils"
import { numberToCurrency } from "../../../../utils/numbers.utils"

export default function ResultPaymentRequestsCP() {
  const {filter, paymentsRequestsResults} = useAppSelector((state: RootState) => state.reports)
  const resolveCustomer = (customer: any): string => {
    if(customer.length === 0) return ""
    return `${customer[0].name} ${customer[0].phone}`
  }

  const resolveUser = (user: any): string => {
    if(user.length === 0) return ""
    return `${user[0].name}`
  }
  return(
      <>
         {(filter.type === "payments-requests" || filter.type === 'projected-payments') && paymentsRequestsResults !== undefined && <>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Fecha</TableCell>
                <TableCell>Cliente</TableCell>
                <TableCell>User</TableCell>
                <TableCell>Expected</TableCell>
                <TableCell>Pagado</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paymentsRequestsResults.map((pay, i) => (
                <TableRow key={i}>
                  <TableCell>{dateUTCToFriendly(pay.createdAt)}</TableCell>
                  <TableCell>{resolveCustomer(pay.customer)}</TableCell>
                  <TableCell>{resolveUser(pay.user)}</TableCell>
                  <TableCell>${numberToCurrency(pay.valueExpected)}</TableCell>
                  <TableCell>${numberToCurrency(pay.valuePayed)}</TableCell>
                </TableRow>
              
              ))}
            </TableBody>
          </Table>
        </TableContainer>

      </>}
      </>
    )
}