import { Button, Grid, Paper } from "@mui/material";
import AppSelector from "../../../../app/components/app-select";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { changeInputReportsAct, getReportsThunk, setImageToPreviewReportsAct } from "../reports.slice";
import LoadingIndicator from "../../../../app/components/loading-indicator";
import ImageShowerCP from "../../../../app/components/image-shower.cp";
import AppDateRangeSelector from "../../../../app/components/app-date-range-selector";
import { dateToInputDate } from "../../../../utils/date.utils";
import moment from "moment";
import { useEffect } from "react";
import { getOfficesThunk } from "../../../offices/offices-list/offices-list.slice";
import { fetchUsersThunk } from "../../../users-list/slice/user-list.slice";

const options = [
  {name: "Situaciones", _id: "situations"},
  {name: "Solicitudes de Pagos", _id: "payments-requests"},
  {name: "Pagos realizados", _id: "payments-made"},
  {name: "Proyecciones de Pago", _id: "projected-payments"},
  {name: "Llamadas", _id: "calls"},
]

export default function ReportFiltersCP() {
  const { filter, loading, imageToPreview } = useAppSelector(state => state.reports) 
  const {offices, gotOffices} = useAppSelector(state => state.offices)
  const {usersOriginal, gotUsers} = useAppSelector(state => state.users)
  const dispatch = useAppDispatch()
  useEffect(() => {
    if(!gotOffices) {
      dispatch(getOfficesThunk())
    }
    if(!gotUsers) {
      dispatch(fetchUsersThunk({enable: true}))
    }
  }, [])
  const changeInput = ({name, val} : {name: string, val: string}) => {
    dispatch(changeInputReportsAct({name, val}))
  }  
  const submitForm = (e: React.FormEvent) => {    
    e.preventDefault()
    if(filter.type === "") return
    dispatch(getReportsThunk({filters: filter}))
  }

  return(
    <>
      <LoadingIndicator open={loading}/>
      <ImageShowerCP imgUrl={imageToPreview} onClose={() => dispatch(setImageToPreviewReportsAct(undefined))}/>
      <Paper sx={{padding: 2}}>
        <form>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <AppSelector options={options} name="type" label="Tipo reporte" value={filter.type} onChange={changeInput} required={true}/>
            </Grid>
            <Grid item xs={12} md={4}>
              <AppSelector label='Office' name="office" options={offices} value={filter.office} onChange={changeInput} />
            </Grid>
            <Grid item xs={12} md={4}>
              <AppSelector label='User' name="user" value={filter.user} options={usersOriginal.filter((u) => u.office && (u.office as any)._id === filter.office).map((u) => ({_id: u._id, name: `${u.name}|${u.lastName}`}))} onChange={changeInput} />
            </Grid>
            <Grid item xs={12} md={4} display={'flex'} justifyContent={'center'}>
              <AppDateRangeSelector id="reportsRange"
                dateStart={moment(filter.startDate).toDate()}
                dateEnd={moment(filter.endDate).toDate()}
                onChange={({dateEnd, dateStart}) => {
                  changeInput({name: "startDate", val: dateToInputDate(dateStart.toISOString())})
                  changeInput({name: "endDate", val: dateToInputDate(dateEnd.toISOString())})
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <Button type="submit" variant="contained" onClick={submitForm}> GENERAR </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </>
  )
}