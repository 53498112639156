import { Close } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { addUserPercentageThunk, closeUserPercentageDialogAct, updateInputUserPercentegeDialogAct } from "../slice/user-percentage.slice";
import AppSelector from "../../../app/components/app-select";
import AppTextField from "../../../app/components/app-textfield";
import { useEffect } from "react";
import { fetchUsersThunk } from "../../users-list/slice/user-list.slice";
import { getOfficesThunk } from "../../offices/offices-list/offices-list.slice";
import { TypePercentageEnum } from "../slice/users-percentage.state";

const arrayLead = [TypePercentageEnum.leadWorker, TypePercentageEnum.officeLead, TypePercentageEnum.subLead]

export default function UserPerncetageFormDialog() {
  const dispatch = useAppDispatch()
  const { userPercentageDialog  } = useAppSelector((state) => state.usersPercentage)
  
  const { users  } = useAppSelector((state) => state.users)
  const { offices  } = useAppSelector((state) => state.offices)

  useEffect(() => {
    dispatch(fetchUsersThunk({enable: true}))
    dispatch(getOfficesThunk())
  }, [])

  const closeDialog = () => dispatch(closeUserPercentageDialogAct())
  
  const updateInput = ({name, val} : {name: string, val: any}) => { 
    console.log({name, val});
    dispatch(updateInputUserPercentegeDialogAct({key: name, value: val}))
  }

  const submitForm = (e: any) => {
    e.preventDefault()
    dispatch(addUserPercentageThunk(userPercentageDialog!))
  }

  return (
    <Dialog open={userPercentageDialog !== undefined}>
      <IconButton className={'closeDialog'} onClick={closeDialog} > <Close/> </IconButton>
      <DialogTitle> Porcentaje de usuario </DialogTitle>
      {userPercentageDialog !== undefined && <form onSubmit={submitForm}>
        <DialogContent sx={{minWidth: 500}} > 
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <AppSelector label="Tipo" name="type" value={userPercentageDialog!.type} required options={[
                {_id: "collector", name: 'Cobrador'},
                {_id: "worker", name: 'Licenciado'},
                {_id: "leadWorker", name: 'Lider pupilo'},
                {_id: "officeLead", name: 'Lider Oficina'},
                {_id: "subLead", name: 'Sublider'},
                {_id: "partner", name: 'Socio'},
                {_id: "mainPartner", name: 'Principal'},
              ]} onChange={updateInput}/>
            </Grid>
            <Grid item xs={12}>
              <AppSelector options={offices} name="office" value={userPercentageDialog!.office} label="Oficina" onChange={updateInput}/>
            </Grid>
            <Grid item xs={12}>
              <AppSelector required options={users.filter((u) => {
                const office = userPercentageDialog!.office
                if(office !== '' && u.office === null) return false
                if(userPercentageDialog!.type === TypePercentageEnum.worker && office === '') return u.level === 4
                if(userPercentageDialog!.type === TypePercentageEnum.worker && office !== '') return u.level === 4 && (u.office as any)._id === office
                
                if(userPercentageDialog!.type === TypePercentageEnum.collector && office === '') return u.level === 5;
                if(userPercentageDialog!.type === TypePercentageEnum.collector && office !== "") return u.level === 5 && (u.office as any)._id === office

                if(arrayLead.indexOf(userPercentageDialog!.type) !== -1 && office === '') return (u.level === 3 || u.level === 2);
                if(arrayLead.indexOf(userPercentageDialog!.type) !== -1 && office !== "") return (u.level === 3 || u.level === 2) && (u.office as any)._id === office

                return true
              })} value={userPercentageDialog!.user} name="user" label="Usuario" onChange={updateInput}/>
            </Grid>
            <Grid item xs={12}>
              <AppTextField required label="Porcentaje" name="percentage" type="number" value={userPercentageDialog!.percentage} inputProps={{min: 0, max: 100, step: 0.01}} onChange={updateInput}/>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{paddingRight: 3, paddingBottom: 2}}>
          <Button variant="contained" type="submit"> GUARDAR </Button>
          <Button variant="contained" onClick={closeDialog} color="error"> CANCELAR </Button>
        </DialogActions>
      </form>}
    </Dialog>
  )
} 