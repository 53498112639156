import RelUserToTwilioNumberDialog from "../features/twilio-numbers/components/rel-user-to-number-dialog";
import TwilioNumberFormDialog from "../features/twilio-numbers/components/twilio-number-form-dialog";
import TwilioNumbersList from "../features/twilio-numbers/components/twilio-numbers-list";
import TwilioNumbersOpts from "../features/twilio-numbers/components/twilio-numbers-opts";

export default function TwilioNumbersView() {


  return (
    <>
      <TwilioNumbersOpts/>
      <TwilioNumbersList/>
      <TwilioNumberFormDialog/>
      <RelUserToTwilioNumberDialog/>
    </>
  )
}