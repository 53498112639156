import { Button, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import LoadingIndicator from "../../../app/components/loading-indicator";
import { useEffect } from "react";
import { changeUserToRelToNumberAct, displayRelUserToNumberFormAct, getEnableUsersThunk, getTwilioNumbersThunk } from "../slice/twilio-numbers.slice";
import { Person } from "@mui/icons-material";
export default function TwilioNumbersList() {
  const dispatch = useAppDispatch()
  const {twilioNumbers, loading  } = useAppSelector((state) => state.twilioNumbers) 

  useEffect(() => {
    dispatch(getTwilioNumbersThunk())
    dispatch(getEnableUsersThunk())
  }, [])

  const displayRelUserToNumberForm = () => dispatch(displayRelUserToNumberFormAct(true))

  return (
    <Paper sx={{padding: 2, marginBottom: 1}}>
      <LoadingIndicator open={loading}/>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell> PNID </TableCell>
              <TableCell> Number </TableCell>
              <TableCell> Number friendly </TableCell>
              <TableCell> User </TableCell>
              <TableCell> Options </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {twilioNumbers.map((twilioNumber) => (
              <TableRow key={`tw_${twilioNumber._id}`}>
                <TableCell> {twilioNumber.PNID} </TableCell>
                <TableCell> {twilioNumber.number} </TableCell>
                <TableCell> {twilioNumber.friendlyNumber} </TableCell>
                <TableCell> {twilioNumber.user?.email}  
                  <IconButton 
                    onClick={() => {
                      dispatch(displayRelUserToNumberForm())
                      dispatch(changeUserToRelToNumberAct({
                        userId: twilioNumber.user?._id,
                        twilioNumber: twilioNumber.number
                      }))
                    }}
                    color="secondary"
                    > <Person fontSize="small"/> </IconButton> 
                </TableCell>
                <TableCell> </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}